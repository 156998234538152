import { QueryClient } from "react-query";
import { consultStaleTime } from "@src/queries/staletimes";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
      suspense: true,
      staleTime: consultStaleTime,
    },
  },
});

export const queryClientWithoutSuspense = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
      staleTime: consultStaleTime,
    },
  },
});
